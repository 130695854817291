export const citys = {
  options: [
    {
      value: '0',
      label: '中国大陆',
      children: [
        {
          label: '北京市',
          abbreviate: '京',
          value: '110000'
        },
        {
          label: '天津市',
          abbreviate: '津',
          value: '120000'
        },
        {
          label: '河北省',
          abbreviate: '冀',
          value: '130000',
          children: [
            {
              label: '石家庄市',
              value: '130100'
            },
            {
              label: '唐山市',
              value: '130200'
            },
            {
              label: '秦皇岛市',
              value: '130300'
            },
            {
              label: '邯郸市',
              value: '130400'
            },
            {
              label: '邢台市',
              value: '130500'
            },
            {
              label: '保定市',
              value: '130600'
            },
            {
              label: '张家口市',
              value: '130700'
            },
            {
              label: '承德市',
              value: '130800'
            },
            {
              label: '沧州市',
              value: '130900'
            },
            {
              label: '廊坊市',
              value: '131000'
            },
            {
              label: '衡水市',
              value: '131100'
            }
          ]
        },
        {
          label: '山西省',
          abbreviate: '晋',
          value: '140000',
          children: [
            {
              label: '太原市',
              value: '140100'
            },
            {
              label: '大同市',
              value: '140200'
            },
            {
              label: '阳泉市',
              value: '140300'
            },
            {
              label: '长治市',
              value: '140400'
            },
            {
              label: '晋城市',
              value: '140500'
            },
            {
              label: '朔州市',
              value: '140600'
            },
            {
              label: '晋中市',
              value: '140700'
            },
            {
              label: '运城市',
              value: '140800'
            },
            {
              label: '忻州市',
              value: '140900'
            },
            {
              label: '临汾市',
              value: '141000'
            },
            {
              label: '吕梁市',
              value: '141100'
            }
          ]
        },
        {
          label: '内蒙古自治区',
          abbreviate: '蒙',
          value: '150000',
          children: [
            {
              label: '呼和浩特市',
              value: '150100'
            },
            {
              label: '包头市',
              value: '150200'
            },
            {
              label: '乌海市',
              value: '150300'
            },
            {
              label: '赤峰市',
              value: '150400'
            },
            {
              label: '通辽市',
              value: '150500'
            },
            {
              label: '鄂尔多斯市',
              value: '150600'
            },
            {
              label: '呼伦贝尔市',
              value: '150700'
            },
            {
              label: '巴彦淖尔市',
              value: '150800'
            },
            {
              label: '乌兰察布市',
              value: '150900'
            },
            {
              label: '兴安盟',
              value: '152200'
            },
            {
              label: '锡林郭勒盟',
              value: '152500'
            },
            {
              label: '阿拉善盟',
              value: '152900'
            }
          ]
        },
        {
          label: '辽宁省',
          abbreviate: '辽',
          value: '210000',
          children: [
            {
              label: '沈阳市',
              value: '210100'
            },
            {
              label: '大连市',
              value: '210200'
            },
            {
              label: '鞍山市',
              value: '210300'
            },
            {
              label: '抚顺市',
              value: '210400'
            },
            {
              label: '本溪市',
              value: '210500'
            },
            {
              label: '丹东市',
              value: '210600'
            },
            {
              label: '锦州市',
              value: '210700'
            },
            {
              label: '营口市',
              value: '210800'
            },
            {
              label: '阜新市',
              value: '210900'
            },
            {
              label: '辽阳市',
              value: '211000'
            },
            {
              label: '盘锦市',
              value: '211100'
            },
            {
              label: '铁岭市',
              value: '211200'
            },
            {
              label: '朝阳市',
              value: '211300'
            },
            {
              label: '葫芦岛市',
              value: '211400'
            }
          ]
        },
        {
          label: '吉林省',
          abbreviate: '吉',
          value: '220000',
          children: [
            {
              label: '长春市',
              value: '220100'
            },
            {
              label: '吉林市',
              value: '220200'
            },
            {
              label: '四平市',
              value: '220300'
            },
            {
              label: '辽源市',
              value: '220400'
            },
            {
              label: '通化市',
              value: '220500'
            },
            {
              label: '白山市',
              value: '220600'
            },
            {
              label: '松原市',
              value: '220700'
            },
            {
              label: '白城市',
              value: '220800'
            },
            {
              label: '延边朝鲜族自治州',
              value: '222400'
            }
          ]
        },
        {
          label: '黑龙江省',
          abbreviate: '黑',
          value: '230000',
          children: [
            {
              label: '哈尔滨市',
              value: '230100'
            },
            {
              label: '齐齐哈尔市',
              value: '230200'
            },
            {
              label: '鸡西市',
              value: '230300'
            },
            {
              label: '鹤岗市',
              value: '230400'
            },
            {
              label: '双鸭山市',
              value: '230500'
            },
            {
              label: '大庆市',
              value: '230600'
            },
            {
              label: '伊春市',
              value: '230700'
            },
            {
              label: '佳木斯市',
              value: '230800'
            },
            {
              label: '七台河市',
              value: '230900'
            },
            {
              label: '牡丹江市',
              value: '231000'
            },
            {
              label: '黑河市',
              value: '231100'
            },
            {
              label: '绥化市',
              value: '231200'
            },
            {
              label: '大兴安岭地区',
              value: '232700'
            }
          ]
        },
        {
          label: '上海市',
          abbreviate: '沪',
          value: '310000',
          children: [
            {
              label: '黄浦区',
              value: '310101'
            },
            {
              label: '徐汇区',
              value: '310104'
            },
            {
              label: '长宁区',
              value: '310105'
            },
            {
              label: '静安区',
              value: '310106'
            },
            {
              label: '普陀区',
              value: '310107'
            },
            {
              label: '虹口区',
              value: '310109'
            },
            {
              label: '杨浦区',
              value: '310110'
            },
            {
              label: '闵行区',
              value: '310112'
            },
            {
              label: '宝山区',
              value: '310113'
            },
            {
              label: '嘉定区',
              value: '310114'
            },
            {
              label: '浦东新区',
              value: '310115'
            },
            {
              label: '金山区',
              value: '310116'
            },
            {
              label: '松江区',
              value: '310117'
            },
            {
              label: '青浦区',
              value: '310118'
            },
            {
              label: '奉贤区',
              value: '310120'
            },
            {
              label: '崇明区',
              value: '310151'
            }
          ]
        },
        {
          label: '江苏省',
          abbreviate: '苏',
          value: '320000',
          children: [
            {
              label: '南京市',
              value: '320100'
            },
            {
              label: '无锡市',
              value: '320200'
            },
            {
              label: '徐州市',
              value: '320300'
            },
            {
              label: '常州市',
              value: '320400'
            },
            {
              label: '苏州市',
              value: '320500'
            },
            {
              label: '南通市',
              value: '320600'
            },
            {
              label: '连云港市',
              value: '320700'
            },
            {
              label: '淮安市',
              value: '320800'
            },
            {
              label: '盐城市',
              value: '320900'
            },
            {
              label: '扬州市',
              value: '321000'
            },
            {
              label: '镇江市',
              value: '321100'
            },
            {
              label: '泰州市',
              value: '321200'
            },
            {
              label: '宿迁市',
              value: '321300'
            }
          ]
        },
        {
          label: '浙江省',
          abbreviate: '浙',
          value: '330000',
          children: [
            {
              label: '杭州市',
              value: '330100'
            },
            {
              label: '宁波市',
              value: '330200'
            },
            {
              label: '温州市',
              value: '330300'
            },
            {
              label: '嘉兴市',
              value: '330400'
            },
            {
              label: '湖州市',
              value: '330500'
            },
            {
              label: '绍兴市',
              value: '330600'
            },
            {
              label: '金华市',
              value: '330700'
            },
            {
              label: '衢州市',
              value: '330800'
            },
            {
              label: '舟山市',
              value: '330900'
            },
            {
              label: '台州市',
              value: '331000'
            },
            {
              label: '丽水市',
              value: '331100'
            }
          ]
        },
        {
          label: '安徽省',
          abbreviate: '皖',
          value: '340000',
          children: [
            {
              label: '合肥市',
              value: '340100'
            },
            {
              label: '芜湖市',
              value: '340200'
            },
            {
              label: '蚌埠市',
              value: '340300'
            },
            {
              label: '淮南市',
              value: '340400'
            },
            {
              label: '马鞍山市',
              value: '340500'
            },
            {
              label: '淮北市',
              value: '340600'
            },
            {
              label: '铜陵市',
              value: '340700'
            },
            {
              label: '安庆市',
              value: '340800'
            },
            {
              label: '黄山市',
              value: '341000'
            },
            {
              label: '滁州市',
              value: '341100'
            },
            {
              label: '阜阳市',
              value: '341200'
            },
            {
              label: '宿州市',
              value: '341300'
            },
            {
              label: '六安市',
              value: '341500'
            },
            {
              label: '亳州市',
              value: '341600'
            },
            {
              label: '池州市',
              value: '341700'
            },
            {
              label: '宣城市',
              value: '341800'
            }
          ]
        },
        {
          label: '福建省',
          abbreviate: '闽',
          value: '350000',
          children: [
            {
              label: '福州市',
              value: '350100'
            },
            {
              label: '厦门市',
              value: '350200'
            },
            {
              label: '莆田市',
              value: '350300'
            },
            {
              label: '三明市',
              value: '350400'
            },
            {
              label: '泉州市',
              value: '350500'
            },
            {
              label: '漳州市',
              value: '350600'
            },
            {
              label: '南平市',
              value: '350700'
            },
            {
              label: '龙岩市',
              value: '350800'
            },
            {
              label: '宁德市',
              value: '350900'
            }
          ]
        },
        {
          label: '江西省',
          abbreviate: '赣',
          value: '360000',
          children: [
            {
              label: '南昌市',
              value: '360100'
            },
            {
              label: '景德镇市',
              value: '360200'
            },
            {
              label: '萍乡市',
              value: '360300'
            },
            {
              label: '九江市',
              value: '360400'
            },
            {
              label: '新余市',
              value: '360500'
            },
            {
              label: '鹰潭市',
              value: '360600'
            },
            {
              label: '赣州市',
              value: '360700'
            },
            {
              label: '吉安市',
              value: '360800'
            },
            {
              label: '宜春市',
              value: '360900'
            },
            {
              label: '抚州市',
              value: '361000'
            },
            {
              label: '上饶市',
              value: '361100'
            }
          ]
        },
        {
          label: '山东省',
          abbreviate: '鲁',
          value: '370000',
          children: [
            {
              label: '济南市',
              value: '370100'
            },
            {
              label: '青岛市',
              value: '370200'
            },
            {
              label: '淄博市',
              value: '370300'
            },
            {
              label: '枣庄市',
              value: '370400'
            },
            {
              label: '东营市',
              value: '370500'
            },
            {
              label: '烟台市',
              value: '370600'
            },
            {
              label: '潍坊市',
              value: '370700'
            },
            {
              label: '济宁市',
              value: '370800'
            },
            {
              label: '泰安市',
              value: '370900'
            },
            {
              label: '威海市',
              value: '371000'
            },
            {
              label: '日照市',
              value: '371100'
            },
            {
              label: '临沂市',
              value: '371300'
            },
            {
              label: '德州市',
              value: '371400'
            },
            {
              label: '聊城市',
              value: '371500'
            },
            {
              label: '滨州市',
              value: '371600'
            },
            {
              label: '菏泽市',
              value: '371700'
            }
          ]
        },
        {
          label: '河南省',
          abbreviate: '豫',
          value: '410000',
          children: [
            {
              label: '郑州市',
              value: '410100'
            },
            {
              label: '开封市',
              value: '410200'
            },
            {
              label: '洛阳市',
              value: '410300'
            },
            {
              label: '平顶山市',
              value: '410400'
            },
            {
              label: '安阳市',
              value: '410500'
            },
            {
              label: '鹤壁市',
              value: '410600'
            },
            {
              label: '新乡市',
              value: '410700'
            },
            {
              label: '焦作市',
              value: '410800'
            },
            {
              label: '濮阳市',
              value: '410900'
            },
            {
              label: '许昌市',
              value: '411000'
            },
            {
              label: '漯河市',
              value: '411100'
            },
            {
              label: '三门峡市',
              value: '411200'
            },
            {
              label: '南阳市',
              value: '411300'
            },
            {
              label: '商丘市',
              value: '411400'
            },
            {
              label: '信阳市',
              value: '411500'
            },
            {
              label: '周口市',
              value: '411600'
            },
            {
              label: '驻马店市',
              value: '411700'
            }
          ]
        },
        {
          label: '湖北省',
          abbreviate: '鄂',
          value: '420000',
          children: [
            {
              label: '武汉市',
              value: '420100'
            },
            {
              label: '黄石市',
              value: '420200'
            },
            {
              label: '十堰市',
              value: '420300'
            },
            {
              label: '宜昌市',
              value: '420500'
            },
            {
              label: '襄阳市',
              value: '420600'
            },
            {
              label: '鄂州市',
              value: '420700'
            },
            {
              label: '荆门市',
              value: '420800'
            },
            {
              label: '孝感市',
              value: '420900'
            },
            {
              label: '荆州市',
              value: '421000'
            },
            {
              label: '黄冈市',
              value: '421100'
            },
            {
              label: '咸宁市',
              value: '421200'
            },
            {
              label: '随州市',
              value: '421300'
            },
            {
              label: '恩施土家族苗族自治州',
              value: '422800'
            }
          ]
        },
        {
          label: '湖南省',
          abbreviate: '湘',
          value: '430000',
          children: [
            {
              label: '长沙市',
              value: '430100'
            },
            {
              label: '株洲市',
              value: '430200'
            },
            {
              label: '湘潭市',
              value: '430300'
            },
            {
              label: '衡阳市',
              value: '430400'
            },
            {
              label: '邵阳市',
              value: '430500'
            },
            {
              label: '岳阳市',
              value: '430600'
            },
            {
              label: '常德市',
              value: '430700'
            },
            {
              label: '张家界市',
              value: '430800'
            },
            {
              label: '益阳市',
              value: '430900'
            },
            {
              label: '郴州市',
              value: '431000'
            },
            {
              label: '永州市',
              value: '431100'
            },
            {
              label: '怀化市',
              value: '431200'
            },
            {
              label: '娄底市',
              value: '431300'
            },
            {
              label: '湘西土家族苗族自治州',
              value: '433100'
            }
          ]
        },
        {
          label: '广东省',
          abbreviate: '粤',
          value: '440000',
          children: [
            {
              label: '广州市',
              value: '440100'
            },
            {
              label: '韶关市',
              value: '440200'
            },
            {
              label: '深圳市',
              value: '440300'
            },
            {
              label: '珠海市',
              value: '440400'
            },
            {
              label: '汕头市',
              value: '440500'
            },
            {
              label: '佛山市',
              value: '440600'
            },
            {
              label: '江门市',
              value: '440700'
            },
            {
              label: '湛江市',
              value: '440800'
            },
            {
              label: '茂名市',
              value: '440900'
            },
            {
              label: '肇庆市',
              value: '441200'
            },
            {
              label: '惠州市',
              value: '441300'
            },
            {
              label: '梅州市',
              value: '441400'
            },
            {
              label: '汕尾市',
              value: '441500'
            },
            {
              label: '河源市',
              value: '441600'
            },
            {
              label: '阳江市',
              value: '441700'
            },
            {
              label: '清远市',
              value: '441800'
            },
            {
              label: '东莞市',
              value: '441900'
            },
            {
              label: '中山市',
              value: '442000'
            },
            {
              label: '潮州市',
              value: '445100'
            },
            {
              label: '揭阳市',
              value: '445200'
            },
            {
              label: '云浮市',
              value: '445300'
            }
          ]
        },
        {
          label: '广西壮族自治区',
          abbreviate: '桂',
          value: '450000',
          children: [
            {
              label: '南宁市',
              value: '450100'
            },
            {
              label: '柳州市',
              value: '450200'
            },
            {
              label: '桂林市',
              value: '450300'
            },
            {
              label: '梧州市',
              value: '450400'
            },
            {
              label: '北海市',
              value: '450500'
            },
            {
              label: '防城港市',
              value: '450600'
            },
            {
              label: '钦州市',
              value: '450700'
            },
            {
              label: '贵港市',
              value: '450800'
            },
            {
              label: '玉林市',
              value: '450900'
            },
            {
              label: '百色市',
              value: '451000'
            },
            {
              label: '贺州市',
              value: '451100'
            },
            {
              label: '河池市',
              value: '451200'
            },
            {
              label: '来宾市',
              value: '451300'
            },
            {
              label: '崇左市',
              value: '451400'
            }
          ]
        },
        {
          label: '海南省',
          abbreviate: '琼',
          value: '460000',
          children: [
            {
              label: '海口市',
              value: '460100'
            },
            {
              label: '三亚市',
              value: '460200'
            },
            {
              label: '三沙市',
              value: '460300'
            },
            {
              label: '儋州市',
              value: '460400'
            }
          ]
        },
        {
          label: '重庆市',
          abbreviate: '渝',
          value: '500000'
        },
        {
          label: '四川省',
          abbreviate: '川',
          value: '510000',
          children: [
            {
              label: '成都市',
              value: '510100'
            },
            {
              label: '自贡市',
              value: '510300'
            },
            {
              label: '攀枝花市',
              value: '510400'
            },
            {
              label: '泸州市',
              value: '510500'
            },
            {
              label: '德阳市',
              value: '510600'
            },
            {
              label: '绵阳市',
              value: '510700'
            },
            {
              label: '广元市',
              value: '510800'
            },
            {
              label: '遂宁市',
              value: '510900'
            },
            {
              label: '内江市',
              value: '511000'
            },
            {
              label: '乐山市',
              value: '511100'
            },
            {
              label: '南充市',
              value: '511300'
            },
            {
              label: '眉山市',
              value: '511400'
            },
            {
              label: '宜宾市',
              value: '511500'
            },
            {
              label: '广安市',
              value: '511600'
            },
            {
              label: '达州市',
              value: '511700'
            },
            {
              label: '雅安市',
              value: '511800'
            },
            {
              label: '巴中市',
              value: '511900'
            },
            {
              label: '资阳市',
              value: '512000'
            },
            {
              label: '阿坝藏族羌族自治州',
              value: '513200'
            },
            {
              label: '甘孜藏族自治州',
              value: '513300'
            },
            {
              label: '凉山彝族自治州',
              value: '513400'
            }
          ]
        },
        {
          label: '贵州省',
          abbreviate: '贵',
          value: '520000',
          children: [
            {
              label: '贵阳市',
              value: '520100'
            },
            {
              label: '六盘水市',
              value: '520200'
            },
            {
              label: '遵义市',
              value: '520300'
            },
            {
              label: '安顺市',
              value: '520400'
            },
            {
              label: '毕节市',
              value: '520500'
            },
            {
              label: '铜仁市',
              value: '520600'
            },
            {
              label: '黔西南布依族苗族自治州',
              value: '522300'
            },
            {
              label: '黔东南苗族侗族自治州',
              value: '522600'
            },
            {
              label: '黔南布依族苗族自治州',
              value: '522700'
            }
          ]
        },
        {
          label: '云南省',
          abbreviate: '云',
          value: '530000',
          children: [
            {
              label: '昆明市',
              value: '530100'
            },
            {
              label: '曲靖市',
              value: '530300'
            },
            {
              label: '玉溪市',
              value: '530400'
            },
            {
              label: '保山市',
              value: '530500'
            },
            {
              label: '昭通市',
              value: '530600'
            },
            {
              label: '丽江市',
              value: '530700'
            },
            {
              label: '普洱市',
              value: '530800'
            },
            {
              label: '临沧市',
              value: '530900'
            },
            {
              label: '楚雄彝族自治州',
              value: '532300'
            },
            {
              label: '红河哈尼族彝族自治州',
              value: '532500'
            },
            {
              label: '文山壮族苗族自治州',
              value: '532600'
            },
            {
              label: '西双版纳傣族自治州',
              value: '532800'
            },
            {
              label: '大理白族自治州',
              value: '532900'
            },
            {
              label: '德宏傣族景颇族自治州',
              value: '533100'
            },
            {
              label: '怒江傈僳族自治州',
              value: '533300'
            },
            {
              label: '迪庆藏族自治州',
              value: '533400'
            }
          ]
        },
        {
          label: '西藏自治区',
          abbreviate: '藏',
          value: '540000',
          children: [
            {
              label: '拉萨市',
              value: '540100'
            },
            {
              label: '日喀则市',
              value: '540200'
            },
            {
              label: '昌都市',
              value: '540300'
            },
            {
              label: '林芝市',
              value: '540400'
            },
            {
              label: '山南市',
              value: '540500'
            },
            {
              label: '那曲市',
              value: '540600'
            },
            {
              label: '阿里地区',
              value: '542500'
            }
          ]
        },
        {
          label: '陕西省',
          abbreviate: '陕',
          value: '610000',
          children: [
            {
              label: '西安市',
              value: '610100'
            },
            {
              label: '铜川市',
              value: '610200'
            },
            {
              label: '宝鸡市',
              value: '610300'
            },
            {
              label: '咸阳市',
              value: '610400'
            },
            {
              label: '渭南市',
              value: '610500'
            },
            {
              label: '延安市',
              value: '610600'
            },
            {
              label: '汉中市',
              value: '610700'
            },
            {
              label: '榆林市',
              value: '610800'
            },
            {
              label: '安康市',
              value: '610900'
            },
            {
              label: '商洛市',
              value: '611000'
            }
          ]
        },
        {
          label: '甘肃省',
          abbreviate: '甘',
          value: '620000',
          children: [
            {
              label: '兰州市',
              value: '620100'
            },
            {
              label: '嘉峪关市',
              value: '620200'
            },
            {
              label: '金昌市',
              value: '620300'
            },
            {
              label: '白银市',
              value: '620400'
            },
            {
              label: '天水市',
              value: '620500'
            },
            {
              label: '武威市',
              value: '620600'
            },
            {
              label: '张掖市',
              value: '620700'
            },
            {
              label: '平凉市',
              value: '620800'
            },
            {
              label: '酒泉市',
              value: '620900'
            },
            {
              label: '庆阳市',
              value: '621000'
            },
            {
              label: '定西市',
              value: '621100'
            },
            {
              label: '陇南市',
              value: '621200'
            },
            {
              label: '临夏回族自治州',
              value: '622900'
            },
            {
              label: '甘南藏族自治州',
              value: '623000'
            }
          ]
        },
        {
          label: '青海省',
          abbreviate: '青',
          value: '630000',
          children: [
            {
              label: '西宁市',
              value: '630100'
            },
            {
              label: '海东市',
              value: '630200'
            },
            {
              label: '海北藏族自治州',
              value: '632200'
            },
            {
              label: '黄南藏族自治州',
              value: '632300'
            },
            {
              label: '海南藏族自治州',
              value: '632500'
            },
            {
              label: '果洛藏族自治州',
              value: '632600'
            },
            {
              label: '玉树藏族自治州',
              value: '632700'
            },
            {
              label: '海西蒙古族藏族自治州',
              value: '632800'
            }
          ]
        },
        {
          label: '宁夏回族自治区',
          abbreviate: '宁',
          value: '640000',
          children: [
            {
              label: '银川市',
              value: '640100'
            },
            {
              label: '石嘴山市',
              value: '640200'
            },
            {
              label: '吴忠市',
              value: '640300'
            },
            {
              label: '固原市',
              value: '640400'
            },
            {
              label: '中卫市',
              value: '640500'
            }
          ]
        },
        {
          label: '新疆维吾尔自治区',
          abbreviate: '新',
          value: '650000',
          children: [
            {
              label: '乌鲁木齐市',
              value: '650100'
            },
            {
              label: '克拉玛依市',
              value: '650200'
            },
            {
              label: '吐鲁番市',
              value: '650400'
            },
            {
              label: '哈密市',
              value: '650500'
            },
            {
              label: '昌吉回族自治州',
              value: '652300'
            },
            {
              label: '博尔塔拉蒙古自治州',
              value: '652700'
            },
            {
              label: '巴音郭楞蒙古自治州',
              value: '652800'
            },
            {
              label: '阿克苏地区',
              value: '652900'
            },
            {
              label: '克孜勒苏柯尔克孜自治州',
              value: '653000'
            },
            {
              label: '喀什地区',
              value: '653100'
            },
            {
              label: '和田地区',
              value: '653200'
            },
            {
              label: '伊犁哈萨克自治州',
              value: '654000'
            },
            {
              label: '塔城地区',
              value: '654200'
            },
            {
              label: '阿勒泰地区',
              value: '654300'
            }
          ]
        }
      ]
    },
    {
      label: '海外',
      value: 'qt',
      children: [
        {
          id: '0093',
          label: '阿富汗',
          value: '2',
          name_en: 'Afghanistan'
        },
        {
          id: '00355',
          value: '3',
          forbid: false,
          label: '阿尔巴尼亚',
          name_en: 'Albania'
        },
        {
          id: '00213',
          value: '4',
          forbid: false,
          label: '阿尔及利亚',
          name_en: 'Algeria'
        },
        {
          id: '00376',
          value: '5',
          forbid: false,
          label: '安道尔共和国',
          name_en: 'Andorra'
        },
        {
          id: '00244',
          value: '1',
          forbid: false,
          label: '安哥拉',
          name_en: 'Angola'
        },
        {
          id: '001264',
          value: '6',
          forbid: false,
          label: '安圭拉岛',
          name_en: 'Anguilla'
        },
        {
          id: '001268',
          value: '7',
          forbid: false,
          label: '安提瓜和巴布达',
          name_en: 'Antigua and Barbuda'
        },
        {
          id: '0054',
          value: '8',
          forbid: false,
          label: '阿根廷',
          name_en: 'Argentina'
        },
        {
          id: '00374',
          value: '9',
          forbid: false,
          label: '亚美尼亚',
          name_en: 'Armenia'
        },
        {
          id: '00297',
          value: '194',
          forbid: false,
          label: '阿鲁巴',
          name_en: 'Aruba'
        },
        {
          id: '00247',
          value: '10',
          forbid: false,
          label: '阿森松',
          name_en: 'Ascension'
        },
        {
          id: '0061',
          value: '11',
          forbid: false,
          label: '澳大利亚',
          name_en: 'Australia'
        },
        {
          id: '00672',
          value: '195',
          forbid: false,
          label: '澳大利亚海外领地',
          name_en: 'Australian overseas territories'
        },
        {
          id: '0043',
          value: '12',
          forbid: false,
          label: '奥地利',
          name_en: 'Austria'
        },
        {
          id: '00994',
          value: '13',
          forbid: false,
          label: '阿塞拜疆',
          name_en: 'Azerbaijan'
        },
        {
          id: '001242',
          value: '14',
          forbid: false,
          label: '巴哈马',
          name_en: 'Bahamas'
        },
        {
          id: '00973',
          value: '15',
          forbid: false,
          label: '巴林',
          name_en: 'Bahrain'
        },
        {
          id: '00880',
          value: '16',
          forbid: true,
          label: '孟加拉国',
          name_en: 'Bangladesh'
        },
        {
          id: '001246',
          value: '17',
          forbid: false,
          label: '巴巴多斯',
          name_en: 'Barbados'
        },
        {
          id: '00375',
          value: '18',
          forbid: false,
          label: '白俄罗斯',
          name_en: 'Belarus'
        },
        {
          id: '0032',
          value: '19',
          forbid: false,
          label: '比利时',
          name_en: 'Belgium'
        },
        {
          id: '00501',
          value: '20',
          forbid: false,
          label: '伯利兹',
          name_en: 'Belize'
        },
        {
          id: '00229',
          value: '21',
          forbid: false,
          label: '贝宁',
          name_en: 'Benin'
        },
        {
          id: '001441',
          value: '22',
          forbid: false,
          label: '百慕大群岛',
          name_en: 'Bermuda'
        },
        {
          id: '00975',
          value: '196',
          forbid: false,
          label: '不丹',
          name_en: 'Bhutan'
        },
        {
          id: '00591',
          value: '23',
          forbid: false,
          label: '玻利维亚',
          name_en: 'Bolivia'
        },
        {
          id: '00387',
          value: '197',
          forbid: false,
          label: '波斯尼亚和黑塞哥维那',
          name_en: 'Bosnia and Herzegovina'
        },
        {
          id: '00267',
          value: '24',
          forbid: false,
          label: '博茨瓦纳',
          name_en: 'Botswana'
        },
        {
          id: '0055',
          value: '25',
          forbid: false,
          label: '巴西',
          name_en: 'Brazil'
        },
        {
          id: '001284',
          value: '229',
          forbid: false,
          label: '英属维尔京群岛',
          name_en: 'British Virgin Islands'
        },
        {
          id: '00673',
          value: '26',
          forbid: false,
          label: '文莱',
          name_en: 'Brunei'
        },
        {
          id: '00359',
          value: '27',
          forbid: false,
          label: '保加利亚',
          name_en: 'Bulgaria'
        },
        {
          id: '00226',
          value: '28',
          forbid: false,
          label: '布基纳法索',
          name_en: 'Burkina Faso'
        },
        {
          id: '00257',
          value: '30',
          forbid: false,
          label: '布隆迪',
          name_en: 'Burundi'
        },
        {
          id: '00855',
          value: '85',
          forbid: false,
          label: '柬埔寨',
          name_en: 'Cambodia'
        },
        {
          id: '00237',
          value: '31',
          forbid: true,
          label: '喀麦隆',
          name_en: 'Cameroon'
        },
        {
          id: '001',
          value: '32',
          forbid: true,
          label: '加拿大',
          name_en: 'Canada'
        },
        {
          id: '00238',
          value: '198',
          forbid: false,
          label: '佛得角',
          name_en: 'Cape Verde'
        },
        {
          id: '001345',
          value: '33',
          forbid: false,
          label: '开曼群岛',
          name_en: 'Cayman Islands'
        },
        {
          id: '00236',
          value: '34',
          forbid: true,
          label: '中非共和国',
          name_en: 'Central African Republic'
        },
        {
          id: '00235',
          value: '35',
          forbid: true,
          label: '乍得',
          name_en: 'Chad'
        },
        {
          id: '0056',
          value: '36',
          forbid: false,
          label: '智利',
          name_en: 'Chile'
        },
        {
          id: '0057',
          value: '38',
          forbid: false,
          label: '哥伦比亚',
          name_en: 'Colombia'
        },
        {
          id: '00269',
          value: '199',
          forbid: false,
          label: '科摩罗群岛',
          name_en: 'Comoros Islands'
        },
        {
          id: '00243',
          value: '39',
          forbid: true,
          label: '刚果',
          name_en: 'Congo'
        },
        {
          id: '00682',
          value: '40',
          forbid: false,
          label: '库克群岛',
          name_en: 'Cook Islands.'
        },
        {
          id: '00506',
          value: '41',
          forbid: false,
          label: '哥斯达黎加',
          name_en: 'Costa Rica'
        },
        {
          id: '00385',
          value: '200',
          forbid: false,
          label: '克罗地亚',
          name_en: 'Croatia'
        },
        {
          id: '0053',
          value: '42',
          forbid: true,
          label: '古巴',
          name_en: 'Cuba'
        },
        {
          id: '00357',
          value: '43',
          forbid: false,
          label: '塞浦路斯',
          name_en: 'Cyprus'
        },
        {
          id: '00420',
          value: '44',
          forbid: false,
          label: '捷克',
          name_en: 'Czech Republic'
        },
        {
          id: '0045',
          value: '45',
          forbid: false,
          label: '丹麦',
          name_en: 'Denmark'
        },
        {
          id: '00246',
          value: '201',
          forbid: false,
          label: '迭戈加西亚群岛',
          name_en: 'Diego Garcia'
        },
        {
          id: '00253',
          value: '46',
          forbid: false,
          label: '吉布提',
          name_en: 'Djibouti'
        },
        {
          id: '001767',
          value: '232',
          forbid: false,
          label: '多米尼克国',
          name_en: 'Dominica'
        },
        {
          id: '001809',
          value: '47',
          forbid: false,
          label: '多米尼加共和国',
          name_en: 'Dominican Republic'
        },
        {
          id: '00670',
          value: '202',
          forbid: false,
          label: '东帝汶',
          name_en: 'East Timor'
        },
        {
          id: '00593',
          value: '48',
          forbid: false,
          label: '厄瓜多尔',
          name_en: 'Ecuador'
        },
        {
          id: '0020',
          value: '49',
          forbid: false,
          label: '埃及',
          name_en: 'Egypt'
        },
        {
          id: '00503',
          value: '50',
          forbid: false,
          label: '萨尔瓦多',
          name_en: 'El Salvador'
        },
        {
          id: '009714',
          value: '230',
          forbid: false,
          label: '迪拜酋长国',
          name_en: 'Emirate of Dubai'
        },
        {
          id: '00240',
          value: '203',
          forbid: false,
          label: '赤道几内亚',
          name_en: 'Equatorial Guinea'
        },
        {
          id: '00291',
          value: '204',
          forbid: false,
          label: '厄立特里亚',
          name_en: 'Eritrea'
        },
        {
          id: '00372',
          value: '51',
          forbid: false,
          label: '爱沙尼亚',
          name_en: 'Estonia'
        },
        {
          id: '00251',
          value: '52',
          forbid: false,
          label: '埃塞俄比亚',
          name_en: 'Ethiopia'
        },
        {
          id: '00500',
          value: '205',
          forbid: false,
          label: '福克兰群岛',
          name_en: 'Falkland Islands'
        },
        {
          id: '00298',
          value: '206',
          forbid: false,
          label: '法罗群岛',
          name_en: 'Faroe Islands'
        },
        {
          id: '00679',
          value: '53',
          forbid: false,
          label: '斐济',
          name_en: 'Fiji'
        },
        {
          id: '00358',
          value: '54',
          forbid: false,
          label: '芬兰',
          name_en: 'Finland'
        },
        {
          id: '0033',
          value: '55',
          forbid: false,
          label: '法国',
          name_en: 'France'
        },
        {
          id: '00594',
          value: '56',
          forbid: false,
          label: '法属圭亚那',
          name_en: 'French Guiana'
        },
        {
          id: '00689',
          value: '136',
          forbid: false,
          label: '法属玻利尼西亚',
          name_en: 'French Polynesia'
        },
        {
          id: '00241',
          value: '57',
          forbid: false,
          label: '加蓬',
          name_en: 'Gabon'
        },
        {
          id: '00220',
          value: '58',
          forbid: false,
          label: '冈比亚',
          name_en: 'Gambia'
        },
        {
          id: '00995',
          value: '59',
          forbid: false,
          label: '格鲁吉亚',
          name_en: 'Georgia'
        },
        {
          id: '0049',
          value: '60',
          forbid: false,
          label: '德国',
          name_en: 'Germany'
        },
        {
          id: '00233',
          value: '61',
          forbid: false,
          label: '加纳',
          name_en: 'Ghana'
        },
        {
          id: '00350',
          value: '62',
          forbid: false,
          label: '直布罗陀',
          name_en: 'Gibraltar'
        },
        {
          id: '0030',
          value: '63',
          forbid: false,
          label: '希腊',
          name_en: 'Greece'
        },
        {
          id: '00299',
          value: '207',
          forbid: false,
          label: '格陵兰岛',
          name_en: 'Greenland'
        },
        {
          id: '001473',
          value: '64',
          forbid: false,
          label: '格林纳达',
          name_en: 'Grenada'
        },
        {
          id: '00590',
          value: '208',
          forbid: false,
          label: '瓜德罗普',
          name_en: 'Guadeloupe'
        },
        {
          id: '001671',
          value: '65',
          forbid: false,
          label: '关岛',
          name_en: 'Guam'
        },
        {
          id: '00502',
          value: '66',
          forbid: false,
          label: '危地马拉',
          name_en: 'Guatemala'
        },
        {
          id: '00224',
          value: '67',
          forbid: true,
          label: '几内亚',
          name_en: 'Guinea'
        },
        {
          id: '00245',
          value: '209',
          forbid: false,
          label: '几内亚比绍',
          name_en: 'Guinea-Bissau'
        },
        {
          id: '00592',
          value: '68',
          forbid: false,
          label: '圭亚那',
          name_en: 'Guyana'
        },
        {
          id: '00509',
          value: '69',
          forbid: false,
          label: '海地',
          name_en: 'Haiti'
        },
        {
          id: '00504',
          value: '70',
          forbid: false,
          label: '洪都拉斯',
          name_en: 'Honduras'
        },
        {
          id: '0036',
          value: '72',
          forbid: false,
          label: '匈牙利',
          name_en: 'Hungary'
        },
        {
          id: '00354',
          value: '73',
          forbid: false,
          label: '冰岛',
          name_en: 'Iceland'
        },
        {
          id: '0091',
          value: '74',
          forbid: false,
          label: '印度',
          name_en: 'India'
        },
        {
          id: '0062',
          value: '75',
          forbid: false,
          label: '印度尼西亚',
          name_en: 'Indonesia'
        },
        {
          id: '0098',
          value: '76',
          forbid: true,
          label: '伊朗',
          name_en: 'Iran'
        },
        {
          id: '00964',
          value: '77',
          forbid: true,
          label: '伊拉克',
          name_en: 'Iraq'
        },
        {
          id: '00353',
          value: '78',
          forbid: false,
          label: '爱尔兰',
          name_en: 'Ireland'
        },
        {
          id: '00441624',
          value: '235',
          forbid: false,
          label: '马恩岛',
          name_en: 'Isle of Man'
        },
        {
          id: '00972',
          value: '79',
          forbid: false,
          label: '以色列',
          name_en: 'Israel'
        },
        {
          id: '0039',
          value: '80',
          forbid: false,
          label: '意大利',
          name_en: 'Italy'
        },
        {
          id: '00225',
          value: '81',
          forbid: true,
          label: '科特迪瓦',
          name_en: 'Ivory Coast'
        },
        {
          id: '001876',
          value: '82',
          forbid: false,
          label: '牙买加',
          name_en: 'Jamaica'
        },
        {
          id: '0081',
          value: '83',
          forbid: true,
          label: '日本',
          name_en: 'Japan'
        },
        {
          id: '0044',
          value: '231',
          forbid: false,
          label: '泽西',
          name_en: 'Jersey'
        },
        {
          id: '00962',
          value: '84',
          forbid: false,
          label: '约旦',
          name_en: 'Jordan'
        },
        {
          id: '007',
          value: '86',
          forbid: false,
          label: '哈萨克斯坦',
          name_en: 'Kazakhstan'
        },
        {
          id: '00254',
          value: '87',
          forbid: false,
          label: '肯尼亚',
          name_en: 'Kenya'
        },
        {
          id: '00686',
          value: '210',
          forbid: false,
          label: '基里巴斯',
          name_en: 'Kiribati'
        },
        {
          id: '0082',
          value: '88',
          forbid: false,
          label: '韩国',
          name_en: 'Korea'
        },
        {
          id: '00383',
          value: '234',
          forbid: false,
          label: '科索沃',
          name_en: 'Kosovo'
        },
        {
          id: '00965',
          value: '89',
          forbid: false,
          label: '科威特',
          name_en: 'Kuwait'
        },
        {
          id: '00996',
          value: '90',
          forbid: false,
          label: '吉尔吉斯坦',
          name_en: 'Kyrgyzstan'
        },
        {
          id: '00856',
          value: '91',
          forbid: false,
          label: '老挝',
          name_en: 'Laos'
        },
        {
          id: '00371',
          value: '92',
          forbid: false,
          label: '拉脱维亚',
          name_en: 'Latvia'
        },
        {
          id: '00961',
          value: '93',
          forbid: true,
          label: '黎巴嫩',
          name_en: 'Lebanon'
        },
        {
          id: '00266',
          value: '94',
          forbid: false,
          label: '莱索托',
          name_en: 'Lesotho'
        },
        {
          id: '00231',
          value: '95',
          forbid: true,
          label: '利比里亚',
          name_en: 'Liberia'
        },
        {
          id: '00218',
          value: '96',
          forbid: true,
          label: '利比亚',
          name_en: 'Libya'
        },
        {
          id: '00423',
          value: '97',
          forbid: false,
          label: '列支敦士登',
          name_en: 'Liechtenstein'
        },
        {
          id: '00370',
          value: '98',
          forbid: false,
          label: '立陶宛',
          name_en: 'Lithuania'
        },
        {
          id: '00352',
          value: '99',
          forbid: false,
          label: '卢森堡',
          name_en: 'Luxembourg'
        },
        {
          id: '00389',
          value: '211',
          forbid: false,
          label: '马其顿',
          name_en: 'Macedonia'
        },
        {
          id: '00261',
          value: '101',
          forbid: false,
          label: '马达加斯加',
          name_en: 'Madagascar'
        },
        {
          id: '00265',
          value: '102',
          forbid: false,
          label: '马拉维',
          name_en: 'Malawi'
        },
        {
          id: '0060',
          value: '103',
          forbid: false,
          label: '马来西亚',
          name_en: 'Malaysia'
        },
        {
          id: '00960',
          value: '104',
          forbid: false,
          label: '马尔代夫',
          name_en: 'Maldives'
        },
        {
          id: '00223',
          value: '105',
          forbid: false,
          label: '马里',
          name_en: 'Mali'
        },
        {
          id: '00356',
          value: '106',
          forbid: false,
          label: '马耳他',
          name_en: 'Malta'
        },
        {
          id: '00223',
          value: '107',
          forbid: false,
          label: '马里亚那群岛',
          name_en: 'Mariana Islands'
        },
        {
          id: '00692',
          value: '212',
          forbid: false,
          label: '马绍尔群岛',
          name_en: 'Marshall Islands'
        },
        {
          id: '00596',
          value: '108',
          forbid: false,
          label: '马提尼克',
          name_en: 'Martinique'
        },
        {
          id: '00222',
          value: '213',
          forbid: false,
          label: '毛里塔尼亚',
          name_en: 'Mauritania'
        },
        {
          id: '00230',
          value: '109',
          forbid: false,
          label: '毛里求斯',
          name_en: 'Mauritius'
        },
        {
          id: '0052',
          value: '110',
          forbid: false,
          label: '墨西哥',
          name_en: 'Mexico'
        },
        {
          id: '00691',
          value: '214',
          forbid: false,
          label: '密克罗尼西亚',
          name_en: 'Micronesia'
        },
        {
          id: '00373',
          value: '111',
          forbid: false,
          label: '摩尔多瓦',
          name_en: 'Moldova'
        },
        {
          id: '00377',
          value: '112',
          forbid: false,
          label: '摩纳哥',
          name_en: 'Monaco'
        },
        {
          id: '00976',
          value: '113',
          forbid: false,
          label: '蒙古',
          name_en: 'Mongolia'
        },
        {
          id: '00382',
          value: '215',
          forbid: false,
          label: '黑山',
          name_en: 'Montenegro'
        },
        {
          id: '001664',
          value: '114',
          forbid: false,
          label: '蒙特塞拉特岛',
          name_en: 'Montserrat'
        },
        {
          id: '00212',
          value: '115',
          forbid: false,
          label: '摩洛哥',
          name_en: 'Morocco'
        },
        {
          id: '00258',
          value: '116',
          forbid: false,
          label: '莫桑比克',
          name_en: 'Mozambique'
        },
        {
          id: '0095',
          value: '29',
          forbid: true,
          label: '缅甸',
          name_en: 'Myanmar'
        },
        {
          id: '00264',
          value: '117',
          forbid: false,
          label: '纳米比亚',
          name_en: 'Namibia'
        },
        {
          id: '00674',
          value: '118',
          forbid: false,
          label: '瑙鲁',
          name_en: 'Nauru'
        },
        {
          id: '00977',
          value: '119',
          forbid: false,
          label: '尼泊尔',
          name_en: 'Nepal'
        },
        {
          id: '00599',
          value: '120',
          forbid: false,
          label: '荷属安的列斯',
          name_en: 'Netheriands Antilles'
        },
        {
          id: '0031',
          value: '121',
          forbid: false,
          label: '荷兰',
          name_en: 'Netherlands'
        },
        {
          id: '00687',
          value: '216',
          forbid: false,
          label: '新喀里多尼亚',
          name_en: 'New Caledonia'
        },
        {
          id: '0064',
          value: '122',
          forbid: false,
          label: '新西兰',
          name_en: 'New Zealand'
        },
        {
          id: '00505',
          value: '123',
          forbid: false,
          label: '尼加拉瓜',
          name_en: 'Nicaragua'
        },
        {
          id: '00227',
          value: '124',
          forbid: false,
          label: '尼日尔',
          name_en: 'Niger'
        },
        {
          id: '00234',
          value: '125',
          forbid: false,
          label: '尼日利亚',
          name_en: 'Nigeria'
        },
        {
          id: '00683',
          value: '217',
          forbid: false,
          label: '纽埃岛',
          name_en: 'Niue'
        },
        {
          id: '00850',
          value: '126',
          forbid: true,
          label: '朝鲜',
          name_en: 'North Korea'
        },
        {
          id: '0047',
          value: '127',
          forbid: false,
          label: '挪威',
          name_en: 'Norway'
        },
        {
          id: '00968',
          value: '128',
          forbid: false,
          label: '阿曼',
          name_en: 'Oman'
        },
        {
          id: '0092',
          value: '129',
          forbid: false,
          label: '巴基斯坦',
          name_en: 'Pakistan'
        },
        {
          id: '00680',
          value: '218',
          forbid: false,
          label: '帕劳',
          name_en: 'Palau'
        },
        {
          id: '00970',
          value: '219',
          forbid: false,
          label: '巴勒斯坦',
          name_en: 'Palestine'
        },
        {
          id: '00507',
          value: '130',
          forbid: false,
          label: '巴拿马',
          name_en: 'Panama'
        },
        {
          id: '00675',
          value: '131',
          forbid: false,
          label: '巴布亚新几内亚',
          name_en: 'Papua New Guinea'
        },
        {
          id: '00595',
          value: '132',
          forbid: false,
          label: '巴拉圭',
          name_en: 'Paraguay'
        },
        {
          id: '0051',
          value: '133',
          forbid: false,
          label: '秘鲁',
          name_en: 'Peru'
        },
        {
          id: '0063',
          value: '134',
          forbid: false,
          label: '菲律宾',
          name_en: 'Philippines'
        },
        {
          id: '0048',
          value: '135',
          forbid: false,
          label: '波兰',
          name_en: 'Poland'
        },
        {
          id: '00351',
          value: '137',
          forbid: false,
          label: '葡萄牙',
          name_en: 'Portugal'
        },
        {
          id: '001',
          value: '138',
          forbid: false,
          label: '波多黎各',
          name_en: 'Puerto Rico'
        },
        {
          id: '00974',
          value: '139',
          forbid: false,
          label: '卡塔尔',
          name_en: 'Qatar'
        },
        {
          id: '00262',
          value: '140',
          forbid: false,
          label: '留尼旺',
          name_en: 'Reunion'
        },
        {
          id: '0040',
          value: '141',
          forbid: false,
          label: '罗马尼亚',
          name_en: 'Romania'
        },
        {
          id: '007',
          value: '142',
          forbid: false,
          label: '俄罗斯',
          name_en: 'Russia'
        },
        {
          id: '00250',
          value: '220',
          forbid: false,
          label: '卢旺达',
          name_en: 'Rwanda'
        },
        {
          id: '001869',
          value: '233',
          forbid: false,
          label: '圣基茨和尼维斯',
          name_en: 'Saint Kitts and Nevis'
        },
        {
          id: '00508',
          value: '222',
          forbid: false,
          label: '圣皮埃尔和密克隆群岛',
          name_en: 'Saint Pierre and Miquelon'
        },
        {
          id: '001784',
          value: '144',
          forbid: false,
          label: '圣文森特岛',
          name_en: 'Saint Vincent'
        },
        {
          id: '00684',
          value: '145',
          forbid: false,
          label: '东萨摩亚(美)',
          name_en: 'Samoa Eastern'
        },
        {
          id: '00685',
          value: '146',
          forbid: false,
          label: '西萨摩亚',
          name_en: 'Samoa Western'
        },
        {
          id: '00378',
          value: '147',
          forbid: false,
          label: '圣马力诺',
          name_en: 'San Marino'
        },
        {
          id: '00239',
          value: '148',
          forbid: false,
          label: '圣多美和普林西比',
          name_en: 'Sao Tome and Principe'
        },
        {
          id: '00966',
          value: '149',
          forbid: false,
          label: '沙特阿拉伯',
          name_en: 'Saudi Arabia'
        },
        {
          id: '00221',
          value: '150',
          forbid: false,
          label: '塞内加尔',
          name_en: 'Senegal'
        },
        {
          id: '00381',
          value: '223',
          forbid: false,
          label: '塞尔维亚',
          name_en: 'Serbia'
        },
        {
          id: '00248',
          value: '151',
          forbid: false,
          label: '塞舌尔',
          name_en: 'Seychelles'
        },
        {
          id: '00232',
          value: '152',
          forbid: false,
          label: '塞拉利昂',
          name_en: 'Sierra Leone'
        },
        {
          id: '0065',
          value: '153',
          forbid: true,
          label: '新加坡',
          name_en: 'Singapore'
        },
        {
          id: '00421',
          value: '154',
          forbid: false,
          label: '斯洛伐克',
          name_en: 'Slovakia'
        },
        {
          id: '00386',
          value: '155',
          forbid: false,
          label: '斯洛文尼亚',
          name_en: 'Slovenia'
        },
        {
          id: '00677',
          value: '156',
          forbid: false,
          label: '所罗门群岛',
          name_en: 'Solomon Islands'
        },
        {
          id: '00252',
          value: '157',
          forbid: true,
          label: '索马里',
          name_en: 'Somalia'
        },
        {
          id: '0027',
          value: '158',
          forbid: false,
          label: '南非',
          name_en: 'South Africa'
        },
        {
          id: '0034',
          value: '159',
          forbid: false,
          label: '西班牙',
          name_en: 'Spain'
        },
        {
          id: '0094',
          value: '160',
          forbid: false,
          label: '斯里兰卡',
          name_en: 'Sri Lanka'
        },
        {
          id: '00290',
          value: '221',
          forbid: false,
          label: '圣赫勒拿岛',
          name_en: 'St.Helena'
        },
        {
          id: '001758',
          value: '161',
          forbid: false,
          label: '圣卢西亚',
          name_en: 'St.Lucia'
        },
        {
          id: '001784',
          value: '162',
          forbid: false,
          label: '圣文森特',
          name_en: 'St.Vincent'
        },
        {
          id: '00249',
          value: '163',
          forbid: true,
          label: '苏丹',
          name_en: 'Sudan'
        },
        {
          id: '00597',
          value: '164',
          forbid: false,
          label: '苏里南',
          name_en: 'Suriname'
        },
        {
          id: '00268',
          value: '165',
          forbid: false,
          label: '斯威士兰',
          name_en: 'Swaziland'
        },
        {
          id: '0046',
          value: '166',
          forbid: false,
          label: '瑞典',
          name_en: 'Sweden'
        },
        {
          id: '0041',
          value: '167',
          forbid: false,
          label: '瑞士',
          name_en: 'Switzerland'
        },
        {
          id: '00963',
          value: '168',
          forbid: true,
          label: '叙利亚',
          name_en: 'Syria'
        },
        {
          id: '00992',
          value: '170',
          forbid: false,
          label: '塔吉克斯坦',
          name_en: 'Tajikistan'
        },
        {
          id: '00255',
          value: '171',
          forbid: false,
          label: '坦桑尼亚',
          name_en: 'Tanzania'
        },
        {
          id: '0066',
          value: '172',
          forbid: false,
          label: '泰国',
          name_en: 'Thailand'
        },
        {
          id: '00228',
          value: '173',
          forbid: false,
          label: '多哥',
          name_en: 'Togo'
        },
        {
          id: '00690',
          value: '224',
          forbid: false,
          label: '托克劳群岛',
          name_en: 'Tokelau'
        },
        {
          id: '00676',
          value: '174',
          forbid: false,
          label: '汤加',
          name_en: 'Tonga'
        },
        {
          id: '001868',
          value: '175',
          forbid: false,
          label: '特立尼达和多巴哥',
          name_en: 'Trinidad and Tobago'
        },
        {
          id: '00216',
          value: '176',
          forbid: false,
          label: '突尼斯',
          name_en: 'Tunisia'
        },
        {
          id: '0090',
          value: '177',
          forbid: false,
          label: '土耳其',
          name_en: 'Turkey'
        },
        {
          id: '00993',
          value: '178',
          forbid: false,
          label: '土库曼斯坦',
          name_en: 'Turkmenistan'
        },
        {
          id: '00688',
          value: '225',
          forbid: false,
          label: '图瓦卢',
          name_en: 'Tuvalu'
        },
        {
          id: '00256',
          value: '179',
          forbid: false,
          label: '乌干达',
          name_en: 'Uganda'
        },
        {
          id: '00380',
          value: '180',
          forbid: false,
          label: '乌克兰',
          name_en: 'Ukraine'
        },
        {
          id: '00971',
          value: '181',
          forbid: false,
          label: '阿拉伯联合酋长国',
          name_en: 'United Arab Emirates'
        },
        {
          id: '0044',
          value: '182',
          forbid: false,
          label: '英国',
          name_en: 'United Kingdom'
        },
        {
          id: '001',
          value: '183',
          forbid: true,
          label: '美国',
          name_en: 'United States of America'
        },
        {
          id: '00598',
          value: '184',
          forbid: false,
          label: '乌拉圭',
          name_en: 'Uruguay'
        },
        {
          id: '00998',
          value: '185',
          forbid: false,
          label: '乌兹别克斯坦',
          name_en: 'Uzbekistan'
        },
        {
          id: '00678',
          value: '226',
          forbid: false,
          label: '瓦努阿图',
          name_en: 'Vanuatu'
        },
        {
          id: '00379',
          value: '227',
          forbid: false,
          label: '梵蒂冈城',
          name_en: 'Vatican City'
        },
        {
          id: '0058',
          value: '186',
          forbid: true,
          label: '委内瑞拉',
          name_en: 'Venezuela'
        },
        {
          id: '0084',
          value: '187',
          forbid: false,
          label: '越南',
          name_en: 'Vietnam'
        },
        {
          id: '00681',
          value: '228',
          forbid: false,
          label: '瓦利斯和富图纳',
          name_en: 'Wallis and Futuna'
        },
        {
          id: '00967',
          value: '188',
          forbid: true,
          label: '也门',
          name_en: 'Yemen'
        },
        {
          id: '00338',
          value: '189',
          forbid: false,
          label: '南斯拉夫',
          name_en: 'Yugoslavia'
        },
        {
          id: '00243',
          value: '192',
          forbid: false,
          label: '扎伊尔',
          name_en: 'Zaire'
        },
        {
          id: '00260',
          value: '193',
          forbid: false,
          label: '赞比亚',
          name_en: 'Zambia'
        },
        {
          id: '00263',
          value: '191',
          forbid: true,
          label: '津巴布韦',
          name_en: 'Zimbabwe'
        }
      ]
    },
    {
      label: '港澳台',
      value: 'gat',
      children: [
        {
          label: '台湾省',
          abbreviate: '台',
          value: '710000',
          children: [
            {
              label: '新北市',
              value: '7100001'
            },
            {
              label: '高雄市',
              value: '7100002'
            },
            {
              label: '台中市',
              value: '7100003'
            },
            {
              label: '台北市',
              value: '7100004'
            },
            {
              label: '桃园市',
              value: '7100005'
            },
            {
              label: '台南市',
              value: '7100006'
            },
            {
              label: '基隆市',
              value: '7100007'
            },
            {
              label: '新竹市',
              value: '7100008'
            },
            {
              label: '嘉义市',
              value: '7100009'
            },
            {
              label: '新竹县',
              value: '7100010'
            },
            {
              label: '苗栗县',
              value: '7100011'
            },
            {
              label: '彰化县',
              value: '7100012'
            },
            {
              label: '南投县',
              value: '7100013'
            },
            {
              label: '云林县',
              value: '7100014'
            },
            {
              label: '嘉义县',
              value: '7100015'
            },
            {
              label: '屏东县',
              value: '7100016'
            },
            {
              label: '宜兰县',
              value: '7100017'
            },
            {
              label: '花莲县',
              value: '7100018'
            },
            {
              label: '台东县',
              value: '7100019'
            },
            {
              label: '澎湖县',
              value: '7100020'
            }
          ]
        },
        {
          label: '香港特别行政区',
          abbreviate: '港',
          value: '810000',
          children: [
            {
              label: '香港岛',
              value: '810001'
            },
            {
              label: '九龙',
              value: '810002'
            },
            {
              label: '新界',
              value: '810003'
            }
          ]
        },
        {
          label: '澳门特别行政区',
          abbreviate: '澳',
          value: '820000',
          children: [
            {
              label: '澳门半岛',
              value: '820001'
            },
            {
              label: '澳门离岛',
              value: '820002'
            }
          ]
        }
      ]
    }
  ],
  bankList: [
    {
      value: 'CDB',
      text: '国家开发银行'
    },
    {
      value: 'ICBC',
      text: '中国工商银行'
    },
    {
      value: 'ABC',
      text: '中国农业银行'
    },
    {
      value: 'BOC',
      text: '中国银行'
    },
    {
      value: 'CCB',
      text: '中国建设银行'
    },
    {
      value: 'PSBC',
      text: '中国邮政储蓄银行'
    },
    {
      value: 'COMM',
      text: '交通银行'
    },
    {
      value: 'CMB',
      text: '招商银行'
    },
    {
      value: 'SPDB',
      text: '上海浦东发展银行'
    },
    {
      value: 'CIB',
      text: '兴业银行'
    },
    {
      value: 'HXBANK',
      text: '华夏银行'
    },
    {
      value: 'GDB',
      text: '广东发展银行'
    },
    {
      value: 'CMBC',
      text: '中国民生银行'
    },
    {
      value: 'CITIC',
      text: '中信银行'
    },
    {
      value: 'CEB',
      text: '中国光大银行'
    },
    {
      value: 'EGBANK',
      text: '恒丰银行'
    },
    {
      value: 'CZBANK',
      text: '浙商银行'
    },
    {
      value: 'BOHAIB',
      text: '渤海银行'
    },
    {
      value: 'SPABANK',
      text: '平安银行'
    },
    {
      value: 'SHRCB',
      text: '上海农村商业银行'
    },
    {
      value: 'YXCCB',
      text: '玉溪市商业银行'
    },
    {
      value: 'YDRCB',
      text: '尧都农商行'
    },
    {
      value: 'BJBANK',
      text: '北京银行'
    },
    {
      value: 'SHBANK',
      text: '上海银行'
    },
    {
      value: 'JSBANK',
      text: '江苏银行'
    },
    {
      value: 'HZCB',
      text: '杭州银行'
    },
    {
      value: 'NJCB',
      text: '南京银行'
    },
    {
      value: 'NBBANK',
      text: '宁波银行'
    },
    {
      value: 'HSBANK',
      text: '徽商银行'
    },
    {
      value: 'CSCB',
      text: '长沙银行'
    },
    {
      value: 'CDCB',
      text: '成都银行'
    },
    {
      value: 'CQBANK',
      text: '重庆银行'
    },
    {
      value: 'DLB',
      text: '大连银行'
    },
    {
      value: 'NCB',
      text: '南昌银行'
    },
    {
      value: 'FJHXBC',
      text: '福建海峡银行'
    },
    {
      value: 'HKB',
      text: '汉口银行'
    },
    {
      value: 'WZCB',
      text: '温州银行'
    },
    {
      value: 'QDCCB',
      text: '青岛银行'
    },
    {
      value: 'TZCB',
      text: '台州银行'
    },
    {
      value: 'JXBANK',
      text: '嘉兴银行'
    },
    {
      value: 'CSRCB',
      text: '常熟农村商业银行'
    },
    {
      value: 'NHB',
      text: '南海农村信用联社'
    },
    {
      value: 'CZRCB',
      text: '常州农村信用联社'
    },
    {
      value: 'H3CB',
      text: '内蒙古银行'
    },
    {
      value: 'SXCB',
      text: '绍兴银行'
    },
    {
      value: 'SDEB',
      text: '顺德农商银行'
    },
    {
      value: 'WJRCB',
      text: '吴江农商银行'
    },
    {
      value: 'ZBCB',
      text: '齐商银行'
    },
    {
      value: 'GYCB',
      text: '贵阳市商业银行'
    },
    {
      value: 'ZYCBANK',
      text: '遵义市商业银行'
    },
    {
      value: 'HZCCB',
      text: '湖州市商业银行'
    },
    {
      value: 'DAQINGB',
      text: '龙江银行'
    },
    {
      value: 'JINCHB',
      text: '晋城银行JCBANK'
    },
    {
      value: 'ZJTLCB',
      text: '浙江泰隆商业银行'
    },
    {
      value: 'GDRCC',
      text: '广东省农村信用社联合社'
    },
    {
      value: 'DRCBCL',
      text: '东莞农村商业银行'
    },
    {
      value: 'MTBANK',
      text: '浙江民泰商业银行'
    },
    {
      value: 'GCB',
      text: '广州银行'
    },
    {
      value: 'LYCB',
      text: '辽阳市商业银行'
    },
    {
      value: 'JSRCU',
      text: '江苏省农村信用联合社'
    },
    {
      value: 'LANGFB',
      text: '廊坊银行'
    },
    {
      value: 'CZCB',
      text: '浙江稠州商业银行'
    },
    {
      value: 'DYCB',
      text: '德阳商业银行'
    },
    {
      value: 'JZBANK',
      text: '晋中市商业银行'
    },
    {
      value: 'BOSZ',
      text: '苏州银行'
    },
    {
      value: 'GLBANK',
      text: '桂林银行'
    },
    {
      value: 'URMQCCB',
      text: '乌鲁木齐市商业银行'
    },
    {
      value: 'CDRCB',
      text: '成都农商银行'
    },
    {
      value: 'ZRCBANK',
      text: '张家港农村商业银行'
    },
    {
      value: 'BOD',
      text: '东莞银行'
    },
    {
      value: 'LSBANK',
      text: '莱商银行'
    },
    {
      value: 'BJRCB',
      text: '北京农村商业银行'
    },
    {
      value: 'TRCB',
      text: '天津农商银行'
    },
    {
      value: 'SRBANK',
      text: '上饶银行'
    },
    {
      value: 'FDB',
      text: '富滇银行'
    },
    {
      value: 'CRCBANK',
      text: '重庆农村商业银行'
    },
    {
      value: 'ASCB',
      text: '鞍山银行'
    },
    {
      value: 'NXBANK',
      text: '宁夏银行'
    },
    {
      value: 'BHB',
      text: '河北银行'
    },
    {
      value: 'HRXJB',
      text: '华融湘江银行'
    },
    {
      value: 'ZGCCB',
      text: '自贡市商业银行'
    },
    {
      value: 'YNRCC',
      text: '云南省农村信用社'
    },
    {
      value: 'JLBANK',
      text: '吉林银行'
    },
    {
      value: 'DYCCB',
      text: '东营市商业银行'
    },
    {
      value: 'KLB',
      text: '昆仑银行'
    },
    {
      value: 'ORBANK',
      text: '鄂尔多斯银行'
    },
    {
      value: 'XTB',
      text: '邢台银行'
    },
    {
      value: 'JSB',
      text: '晋商银行'
    },
    {
      value: 'TCCB',
      text: '天津银行'
    },
    {
      value: 'BOYK',
      text: '营口银行'
    },
    {
      value: 'JLRCU',
      text: '吉林农信'
    },
    {
      value: 'SDRCU',
      text: '山东农信'
    },
    {
      value: 'XABANK',
      text: '西安银行'
    },
    {
      value: 'HBRCU',
      text: '河北省农村信用社'
    },
    {
      value: 'NXRCU',
      text: '宁夏黄河农村商业银行'
    },
    {
      value: 'GZRCU',
      text: '贵州省农村信用社'
    },
    {
      value: 'FXCB',
      text: '阜新银行'
    },
    {
      value: 'HBHSBANK',
      text: '湖北银行黄石分行'
    },
    {
      value: 'ZJNX',
      text: '浙江省农村信用社联合社'
    },
    {
      value: 'XXBANK',
      text: '新乡银行'
    },
    {
      value: 'HBYCBANK',
      text: '湖北银行宜昌分行'
    },
    {
      value: 'LSCCB',
      text: '乐山市商业银行'
    },
    {
      value: 'TCRCB',
      text: '江苏太仓农村商业银行'
    },
    {
      value: 'BZMD',
      text: '驻马店银行'
    },
    {
      value: 'GZB',
      text: '赣州银行'
    },
    {
      value: 'WRCB',
      text: '无锡农村商业银行'
    },
    {
      value: 'BGB',
      text: '广西北部湾银行'
    },
    {
      value: 'GRCB',
      text: '广州农商银行'
    },
    {
      value: 'JRCB',
      text: '江苏江阴农村商业银行'
    },
    {
      value: 'BOP',
      text: '平顶山银行'
    },
    {
      value: 'TACCB',
      text: '泰安市商业银行'
    },
    {
      value: 'CGNB',
      text: '南充市商业银行'
    },
    {
      value: 'CCQTGB',
      text: '重庆三峡银行'
    },
    {
      value: 'XLBANK',
      text: '中山小榄村镇银行'
    },
    {
      value: 'HDBANK',
      text: '邯郸银行'
    },
    {
      value: 'KORLABANK',
      text: '库尔勒市商业银行'
    },
    {
      value: 'BOJZ',
      text: '锦州银行'
    },
    {
      value: 'QLBANK',
      text: '齐鲁银行'
    },
    {
      value: 'BOQH',
      text: '青海银行'
    },
    {
      value: 'YQCCB',
      text: '阳泉银行'
    },
    {
      value: 'SJBANK',
      text: '盛京银行'
    },
    {
      value: 'FSCB',
      text: '抚顺银行'
    },
    {
      value: 'ZZBANK',
      text: '郑州银行'
    },
    {
      value: 'SRCB',
      text: '深圳农村商业银行'
    },
    {
      value: 'BANKWF',
      text: '潍坊银行'
    },
    {
      value: 'JJBANK',
      text: '九江银行'
    },
    {
      value: 'JXRCU',
      text: '江西省农村信用'
    },
    {
      value: 'HNRCU',
      text: '河南省农村信用'
    },
    {
      value: 'GSRCU',
      text: '甘肃省农村信用'
    },
    {
      value: 'SCRCU',
      text: '四川省农村信用'
    },
    {
      value: 'GXRCU',
      text: '广西省农村信用'
    },
    {
      value: 'SXRCCU',
      text: '陕西信合'
    },
    {
      value: 'WHRCB',
      text: '武汉农村商业银行'
    },
    {
      value: 'YBCCB',
      text: '宜宾市商业银行'
    },
    {
      value: 'KSRB',
      text: '昆山农村商业银行'
    },
    {
      value: 'SZSBK',
      text: '石嘴山银行'
    },
    {
      value: 'HSBK',
      text: '衡水银行'
    },
    {
      value: 'XYBANK',
      text: '信阳银行'
    },
    {
      value: 'NBYZ',
      text: '鄞州银行'
    },
    {
      value: 'ZJKCCB',
      text: '张家口市商业银行'
    },
    {
      value: 'XCYH',
      text: '许昌银行'
    },
    {
      value: 'JNBANK',
      text: '济宁银行'
    },
    {
      value: 'CBKF',
      text: '开封市商业银行'
    },
    {
      value: 'WHCCB',
      text: '威海市商业银行'
    },
    {
      value: 'HBC',
      text: '湖北银行'
    },
    {
      value: 'BOCD',
      text: '承德银行'
    },
    {
      value: 'BODD',
      text: '丹东银行'
    },
    {
      value: 'JHBANK',
      text: '金华银行'
    },
    {
      value: 'BOCY',
      text: '朝阳银行'
    },
    {
      value: 'LSBC',
      text: '临商银行'
    },
    {
      value: 'BSB',
      text: '包商银行'
    },
    {
      value: 'LZYH',
      text: '兰州银行'
    },
    {
      value: 'BOZK',
      text: '周口银行'
    },
    {
      value: 'DZBANK',
      text: '德州银行'
    },
    {
      value: 'SCCB',
      text: '三门峡银行'
    },
    {
      value: 'AYCB',
      text: '安阳银行'
    },
    {
      value: 'ARCU',
      text: '安徽省农村信用社'
    },
    {
      value: 'HURCB',
      text: '湖北省农村信用社'
    },
    {
      value: 'HNRCC',
      text: '湖南省农村信用社'
    },
    {
      value: 'NYNB',
      text: '广东南粤银行'
    },
    {
      value: 'LYBANK',
      text: '洛阳银行'
    },
    {
      value: 'NHQS',
      text: '农信银清算中心'
    },
    {
      value: 'CBBQS',
      text: '城市商业银行资金清算中心'
    }
  ]
}
